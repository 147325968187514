import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ISymbol {
  character: string;
  pixelWidth: number;
}
@Injectable({
  providedIn: 'root'
})
export class SymbolsService {

  private symbols: BehaviorSubject<ISymbol[]> = new BehaviorSubject<ISymbol[]>([
    { character: `a`, pixelWidth: 6.27 },
    { character: `b`, pixelWidth: 7.07 },
    { character: `c`, pixelWidth: 5.58 },
    { character: `d`, pixelWidth: 7.07 },
    { character: `e`, pixelWidth: 6.71 },
    { character: `f`, pixelWidth: 4.63 },
    { character: `g`, pixelWidth: 6.94 },
    { character: `h`, pixelWidth: 6.86 },
    { character: `i`, pixelWidth: 3.04 },
    { character: `j`, pixelWidth: 3.04 },
    { character: `k`, pixelWidth: 6.27 },
    { character: `l`, pixelWidth: 3.28 },
    { character: `m`, pixelWidth: 10.33 },
    { character: `n`, pixelWidth: 6.89 },
    { character: `o`, pixelWidth: 7.08 },
    { character: `p`, pixelWidth: 7.07 },
    { character: `q`, pixelWidth: 7.07 },
    { character: `r`, pixelWidth: 4.63 },
    { character: `s`, pixelWidth: 5.36 },
    { character: `t`, pixelWidth: 4.82 },
    { character: `u`, pixelWidth: 6.89 },
    { character: `v`, pixelWidth: 6.03 },
    { character: `w`, pixelWidth: 9.32 },
    { character: `x`, pixelWidth: 6.13 },
    { character: `y`, pixelWidth: 5.96 },
    { character: `z`, pixelWidth: 5.65 },
    { character: `_`, pixelWidth: 5.91 },
    { character: `@`, pixelWidth: 11.4 },
    { character: `!`, pixelWidth: 3.31 },
    { character: `£`, pixelWidth: 6.77 },
    { character: `$`, pixelWidth: 6.77 },
    { character: `%`, pixelWidth: 10.3 },
    { character: `^`, pixelWidth: 6.77 },
    { character: `*`, pixelWidth: 5.76 },
    { character: `(`, pixelWidth: 3.89 },
    { character: `)`, pixelWidth: 3.89 },
    { character: `[`, pixelWidth: 3.95 },
    { character: `]`, pixelWidth: 3.95 },
    { character: `-`, pixelWidth: 3.59 },
    { character: `=`, pixelWidth: 6.77 },
    { character: `+`, pixelWidth: 6.77 },
    { character: `'`, pixelWidth: 2.89 },
    { character: `:`, pixelWidth: 2.95 },
    { character: `;`, pixelWidth: 2.95 },
    { character: '`', pixelWidth: 4.52 },
    { character: `.`, pixelWidth: 2.95 },
  ]);

  symbols$: Observable<ISymbol[]> = this.symbols.asObservable();

  constructor() { }
}
