import { animate, animation, style, transition, trigger } from '@angular/animations';

export const animFadeIn = trigger('animFadeIn', [
    transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-in', style({ opacity: '1' }))
    ])
]);

export const animSwishFadeIn = trigger('animSwishFadeIn', [
    transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-in', style({ opacity: '1' })),
    ])
]);