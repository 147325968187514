import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISymbol, SymbolsService } from './symbols.service';

interface ILink {
  label: string;
  locked?: boolean;
  order?: number;
  icon?: string;
}

export interface INavLink extends ILink {
  routerLink?: string;
}

export interface ISocialLink extends ILink {
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  private navLinks: BehaviorSubject<INavLink[]> = new BehaviorSubject<INavLink[]>([]);
  navLinks$: Observable<INavLink[]> = this.navLinks.asObservable();

  private socialLinks: BehaviorSubject<ISocialLink[]> = new BehaviorSubject<ISocialLink[]>([]);
  socialLinks$: Observable<ISocialLink[]> = this.socialLinks.asObservable();

  private symbols: ISymbol[] = [];

  constructor(private symbolsService: SymbolsService) {
    this.symbolsService.symbols$.subscribe(symbols => {
      this.symbols = symbols;

      this.createNavLinks();
      this.createSocialLinks();
    });

  }

  private createNavLinks(): void {
    const newLinks: INavLink[] = [
      { label: `development` },
      { label: `artwork` },
      { label: `about` }
    ];

    newLinks.sort((link1, link2) => this.getStringLengthWithoutSymbols(link2?.label) - this.getStringLengthWithoutSymbols(link1?.label));

    this.navLinks.next(newLinks);
  }

  private createSocialLinks(): void {
    const newLinks: ISocialLink[] = [
      { label: `github` },
      { label: `twitter` },
      { label: `instagram` },
      { label: `imgur` },
      { label: `reddit` },
      { label: `medium` },
      { label: `deviantart` },
      { label: `artstation` },
      { label: `itch.io` },
      { label: `tumblr` },
      { label: `pastebin` },
      { label: `gumroad` },
      { label: `patreon` },
      { label: `dribbble` },
      { label: `ludumdare` },
      { label: `ko-fi` },
    ];

    newLinks.sort((link1, link2) => this.getStringLengthWithoutSymbols(link2?.label) - this.getStringLengthWithoutSymbols(link1?.label));

    this.socialLinks.next(newLinks);
  }

  private getStringLengthWithoutSymbols(input: string): number {
    let length: number = 0;

    for (var i = 0; i < input?.length; i++) {
      const character = input?.charAt(i);
      const symbol = this.symbols?.find(symbol => symbol?.character?.toLowerCase() === character?.toLowerCase());

      length += (symbol) ? 1/*symbol?.pixelWidth*/ : 0;
    }

    return length;
  }
}
