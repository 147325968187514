<div class="window">
    <div class="toolbar">
        <div class="actions">
            <div class="action close"></div>
            <div class="action minimize"></div>
            <div class="action maximize"></div>
        </div>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>