import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { animSwishFadeIn } from './_modules/core/_animations/fade-in.anim';
import { INavLink, ISocialLink, LinksService } from './_modules/core/_services/links.service';

@Component({
  selector: 'my-quiet-sanctuary-for-creative-thoughts-and-vision',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [animSwishFadeIn]
})
export class AppComponent implements OnInit, OnDestroy {

  navLinks: INavLink[] = [];
  socialLinks: ISocialLink[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(private linksService: LinksService) {}

  ngOnInit(): void {
    this.subscriptions.add(this.linksService?.navLinks$?.subscribe(navLinks => {
      this.navLinks = navLinks;
    }));

    this.subscriptions.add(this.linksService?.socialLinks$?.subscribe(socialLinks => {
      this.socialLinks = socialLinks;
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
