<div class="content">
    <div class="feed">

        <app-console>
            <div class="post">
                <div class="image"></div>
            </div>
        </app-console>

    </div>
    <div class="sidebar">
        <div @animSwishFadeIn class="brand-title">Oliver Bott</div>
        <div class="blurb">software developer, artist</div>

        <div class="link-list-header">Pages</div>
        <ul class="link-list">
            <li *ngFor="let navLink of navLinks; let index = index;" [ngClass]="{'locked': !navLink?.routerLink || navLink?.locked }">
                <i *ngIf="!navLink?.routerLink || navLink?.locked" class="fad fa-lock"></i> {{ navLink?.label }}
            </li>
        </ul>

        <div class="link-list-header">Follow Me</div>
        <ul class="link-list">
            <li *ngFor="let socialLink of socialLinks; let index = index;" [ngClass]="{'locked': !socialLink?.url || socialLink?.locked }">
                <i *ngIf="!socialLink?.url || socialLink?.locked" class="fad fa-lock"></i> {{ socialLink?.label }}
            </li>
        </ul>

    </div>
</div>