import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksService } from './_services/links.service';
import { SymbolsService } from './_services/symbols.service';
import { ConsoleComponent } from './_components/console/console.component';


const publicItems: any[] = [
  ConsoleComponent
];

const privateItems: any[] = [

];

@NgModule({
  declarations: [...publicItems, ...privateItems, ConsoleComponent],
  exports: [...publicItems],
  imports: [
    CommonModule
  ],
  providers: [
    LinksService,
    SymbolsService
  ]
})
export class CoreModule { }
